// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-fr-js": () => import("./../src/pages/about.fr.js" /* webpackChunkName: "component---src-pages-about-fr-js" */),
  "component---src-pages-confirmation-en-js": () => import("./../src/pages/confirmation.en.js" /* webpackChunkName: "component---src-pages-confirmation-en-js" */),
  "component---src-pages-confirmation-fr-js": () => import("./../src/pages/confirmation.fr.js" /* webpackChunkName: "component---src-pages-confirmation-fr-js" */),
  "component---src-pages-events-en-js": () => import("./../src/pages/events.en.js" /* webpackChunkName: "component---src-pages-events-en-js" */),
  "component---src-pages-events-fr-js": () => import("./../src/pages/events.fr.js" /* webpackChunkName: "component---src-pages-events-fr-js" */),
  "component---src-pages-features-en-js": () => import("./../src/pages/features.en.js" /* webpackChunkName: "component---src-pages-features-en-js" */),
  "component---src-pages-features-fr-js": () => import("./../src/pages/features.fr.js" /* webpackChunkName: "component---src-pages-features-fr-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-templates-event-page-js": () => import("./../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-feature-page-js": () => import("./../src/templates/feature-page.js" /* webpackChunkName: "component---src-templates-feature-page-js" */)
}

